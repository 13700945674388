import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import queryString from "query-string"
import Layout from "../../../components/Layout"
import Seo from "../../../components/seo"
import { BlogPageBody } from "../../../components/blog-page-body"

const DraftPage: React.FC<PageProps<GatsbyTypes.DraftPageQuery>> = ({ location }) => {
  const { id, draft } = queryString.parse(location.search);
  const [data, setData] = useState<GatsbyTypes.DraftPageQuery | null>(null);

  useEffect(() => {
    fetch(`https://usk.microcms.io/api/v1/blog/${id}?draftKey=${draft}`, {
      headers: {
        'X-MICROCMS-API-KEY' : process.env.API_KEY
      },
    })
      .then(res => res.json())
      .then(res => setData({ microcmsBlog: res }));
  }, []); 

  if (data === null) {
    return null;
  }

  return (
    <Layout>
        <BlogPageBody b={data.microcmsBlog}/>
    </Layout>
  )
}

export default DraftPage

export const Head = ({ data }) => (
    <Seo
      title={data.microcmsBlog.title}
      description={data.microcmsBlog.description}
      image_url={data.microcmsBlog.thumbnail?.url}
      type="article"
    />
  )

export const query = graphql`
  query DraftPage {
    microcmsBlog {
        blogId
        title
        date
        body
        publishedAt
        thumbnail {
          url
        }
        description
        tags {
          name
        }
        category {
          name
        }          
      }
  }
`